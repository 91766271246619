.SupportForm {
    width: 50%;
    display: grid;
    border-radius: 36px;
    border: 12px solid #E4E5E7;
    background: #E4E5E7;
    box-shadow: 2px 2px 4px #A9A9AB,
    -2px -2px 4px #FFFFFF,
    2px 2px 4px #A9A9AB inset,
    -2px -2px 4px #FFFFFF inset;

    padding: 16pt;
    column-gap: 16pt;
    row-gap: 16pt;
}

.field-container {
    min-height: 50px;
    width: auto;
    display: flex;

    background: #E4E5E7;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #A9A9AB,
    inset -2px -2px 4px #FFFFFF;
}

.field {
    outline: none;
    border: none;
    color: #93959A;
    background: none;

    padding: 0 25pt 0 25pt;

    font-size: 18px;
    font-weight: bold;
}

.field-container .input-element {
    width: 100%;
}

.field-container ::placeholder {
    color:#93959A;
}

.field-container .textarea-element {
    width: 100%;
    resize: none;
}

#name-field-container {
    grid-row: 1;
    grid-column: 1 / 2;
}

#email-field-container {
    grid-row: 1;
    grid-column: 2 / 3;
}

#message-field-container {
    grid-column: 1 / 3;
    grid-row: 2;

    padding-top: 8px;
    padding-bottom: 8px;
}

#submit-button {
    grid-column: 1 / 3;
    grid-row: 3;
    justify-self: center;

    width: fit-content;
    height: 50px;

    padding-left: 36px;
    padding-right: 36px;

    border-radius: 12px;
    border: none;
    background: #E4E5E7;
    box-shadow: 2px 2px 4px #A9A9AB,
    -2px -2px 4px #FFFFFF;

    font-weight: bold;
    font-size: 14px;
    color: #636469;
}

#submit-button:active {
    box-shadow: inset 2px 2px 4px #A9A9AB,
    inset -2px -2px 4px #FFFFFF;
}